<template>
  <div class="content">
    <div class="mt-3">
      <div class="col-12 d-flex  flex-wrap">
        <template v-if="this.selectedRows.length===0">
          <div class="input-sm mt--1 col-sm-3 col-12 px-0 dropdown-min-width" style="width: 340px">
            <base-input
              label="Search"
              inputClasses="form-control-sm"
              prepend-icon="fas fa-search"
              placeholder="Search..."
              @change="search"
              v-model="searchQuery"
              type="search"
              @search="search"
            >
            </base-input>
          </div>
          <slot></slot>
          <base-button type="secondary"
                       class="d-inline-block pt-2 pb-2 ml-auto align-self-center font-weight-normal custom-button filter-button-width"
                       @click="$store.commit('showUCFS')">
            <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter <el-badge :value="getFiltersCount" class="mark filter-count-badge" type="warning"></el-badge>
          </base-button>
        </template>
        <template v-else>
          <slot name="deleteButton"></slot>
          <slot name="messageButton"></slot>
        </template>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        row-key="id"
        @selection-change="alertsMultiSelect"
        header-row-class-name="thead-light"
      >
        <el-table-column
          v-for="(column,index) in tableColumns"
          :key="column.label"
          v-bind="column">
          <template scope="scope" v-if="index===2">
            <el-tooltip
              class="item" effect="dark"
              :content="hoverPatientInfo(scope.row)"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <router-link :to="{name: getRoute(scope.row.type), params: {id: scope.row.patientId}}">
                <span class="color-maroon"> {{ getName(scope.row) }} </span>
              </router-link>
            </el-tooltip>
          </template>
          <template scope="scope" v-else-if="column.prop === 'alert_msg'">
            <span v-html="alertMessage(scope.row.alert_msg,scope.row.type)"></span>
          </template>
          <template scope="scope" v-else-if="column.prop === 'created'">
            {{ formatDate(scope.row.date) }}
          </template>
        </el-table-column>
        <el-table-column min-width="180px" align="right" label="Actions">
          <div slot-scope="{ $index, row }" class="d-flex">
            <img
              class="pointer mr-2"
              @click="handleEdit($index, row)"
              src="/img/icons/buttons/message.svg"
              alt=""
            />
            <img
              class="pointer"
              @click="handleDelete($index, row)"
              src="/img/icons/buttons/remove.svg"
              alt=""
            />
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
      <base-pagination
        class="mt-4 pagination-no-border float-right"
        v-model="pagination.current_page"
        :per-page="pagination.per_page"
        :total="pagination.total"
        @input="changePage"
      >
      </base-pagination>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from "element-ui";
import {BasePagination} from "@/components";
import Helper from "@/util/globalHelpers";


export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: ["tableData", "tableColumns", "pagination", "parentSearch",'categories','defaultCategories','filtersCount'],
  data() {
    return {
      searchQuery: '',
      selectedRows: [],

      response : {
        categories: [],
      }
    };
  },
  methods: {
    handleEdit(index, row) {
      this.$emit('onEdit', row);
    },
    handleDelete(index, row) {
      this.$emit('onRemove', row);
    },
    search() {
      this.$emit('search', this.searchQuery);
    },
    changePage(page) {
      this.$emit("changed-page", page);
    },
    alertsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
      this.$emit('alertsMultiSelect', selectedRows);
    },
    getName(row) {
      if (row.name.trim() === '') {
        return row.first_name +' '+ row.last_name;
      }
      // return row.patient_name
      return row.name
    },
    alertMessage(alert, type) {
      let newAlertMessage = alert;
      if (type == 1 || type == '1') {
        if (alert.trim() !== '') {
          if (alert.includes('is next item due in checklist') || alert.includes('checked off any checklist item in over 2 months')) {
            return newAlertMessage;
          } else {
            newAlertMessage = "<b>Checked off </b>" + newAlertMessage;
          }
        }
      }
      return newAlertMessage
    },

    hoverPatientInfo(row){
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true) ? returnable+row.first_name : returnable+''
      returnable = (row.hasOwnProperty('last_name') === true) ? returnable+' '+row.last_name : returnable+''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable+', '+row.email : returnable+''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable+', '+row.phone : returnable+''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null){
        if (!isNaN(Date.parse(row.dob))){
          const date = new Date(row.dob)
          returnable = returnable+', '+Helper.extractAndFormatDate(date.toDateString(),false)
        }
      }else {
        returnable = returnable+''
      }
      returnable = (row.hasOwnProperty('mrn_number') === true && row.mrn_number !== '' && row.mrn_number != null) ? returnable + ', ' + row.mrn_number : returnable + ' '
      return returnable
    },

    clearSelection(){
      this.$refs.multipleTable.clearSelection();
    },

    getRoute(type){
      if(type == 1 || type == '1')
        return 'checklists';
      return 'NutritionReport'
    }
  },
  mounted() {
    this.searchQuery = this.parentSearch;
  },

  computed : {
    getFiltersCount(){
      return this.filtersCount ?? 0;
    },
  }
};
</script>
<style scoped>

.dropdown-min-width{
  min-width: 200px;
  max-width: 70%;
}

.filter-count-badge {
  padding: 0;
}
</style>

<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
