<template>
  <!-- Edit Modal -->

  <modal v-loading="loaders.modal" :show.sync="$store.state.modals.alertsModalVisibility">

    <h5 slot="header" class="modal-title" id="modal-title-default">Message</h5>
    <div class="theme-paragraph-container">
      <p class="theme-paragraph">
        This is a push notification.
        Do not send anything that could be considered Protected Health Information.
        Patients cannot respond to these messages.
      </p>
    </div>

    <validation-observer v-slot="{handleSubmit}" ref="alertsMessageForm">
      <form class="needs-validation mt-4" @submit.prevent="handleSubmit(submitForm)">
        <div>
          <div>
            <el-checkbox @change="clickedCheckbox" class="orange-checkbox" v-model="request.template">
            <span class="text-dark-blue font-weight-500">
              Use Message Template
            </span>
            </el-checkbox>
          </div>

          <div class="mt-3" v-if="request.template">
            <el-select class="select-warning w-100"
              placeholder="Select Message"
              @change="selectMessageTemplate"
              v-model="request.selectedVal">
              <el-option v-for="option in $store.getters.getMessageTemplates"
                        class="select-danger"
                        :value="option.description"
                        :label="option.title"
                        :key="option.id">
              </el-option>
            </el-select>
          </div>

          <div class="mt-4">
            <base-input label="Write Message" :rules="{required: true}" name="note">
                <textarea
                  v-model="request.message"
                  class="form-control"
                  rows="4">
                </textarea>
            </base-input>
          </div>
        </div>

        <div class="float-right mt-4">
          <base-button native-type="submit" type="submit"
                       class="blue-outline-button ml-auto"
                       :disabled="loaders.modal"
          >
            Send Message
          </base-button>
          <base-button
            native-type="submit" type="submit"
            class="btn-primary-action text-white"
            @click="handleSubmitAndRemove"
            :disabled="loaders.modal"
          >
            Send Message & Clear Alert
          </base-button>
        </div>
      </form>
    </validation-observer>
  </modal>

  <!-- Edit Modal Finished -->

</template>

<script>
import _ from 'lodash';
import authHelper from "@/util/authHelper";
export default {
  props: ['loader', 'alert'],
  data() {
    return {
      loaders: {
        modal: false,
      },
      request: {
        template: true,
        selectedVal: '',
        message: '',
      },
    }
  },
  watch: {
    '$store.state.modals.alertsModalVisibility': function() {
      this.$refs.alertsMessageForm.reset();
      this.clearInput();
    }
  },
  methods: {
    clearInput() {
      this.request.template = true;
      this.request.selectedVal = '';
      this.request.message = '';
    },
    submitForm() {
      let vm = this;
      if (vm.request.message==='') return
      vm.loaders.modal = true;
      // send message api.
      const payload = {
        patient_id: this.extractPatientIds,
        notification: vm.request.message
      }
      this.$emit('formSubmit')
      axios.post(`${this.$store.getters.getBaseUrl}/api/nutrition/message`,payload).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.loaders.modal = false
        vm.$store.commit('hideAlertsModal');
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Message',
          message: message
        });
      }).finally(() => {
        vm.loaders.modal = false;
      });
    },
    handleSubmitAndRemove(){
      this.$emit('submitAndClear',this.alert.id)
    },
    clickedCheckbox() {
      if(this.request.template===false)
        this.request.message='';
    },
    selectMessageTemplate() {
      this.request.message = _.cloneDeep(this.request.selectedVal)
    }
  },
  computed: {
    extractPatientIds()
    {
      let patientIds = []
      if (Array.isArray(this.alert)){
        for (const alert of this.alert){
          patientIds.push(alert.user_id)
        }
        patientIds = patientIds.filter((v, i, a) => a.indexOf(v) === i);
      }else {
        patientIds = this.alert.user_id
      }
      return patientIds
    }
  },
  mounted() {
    this.loaders.modal = false
  },
}
</script>

<style scoped>

.theme-paragraph-container {
  margin-top: -35px!important;
  margin-left: -1px;
}
</style>
