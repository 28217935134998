<template>
  <div class="d-flex justify-content-end mt-1">
    <div class="dropdown-container d-flex mr-2 ml-2 dropdown-min-width max_width_150">
      <span class="alert-page-dropdowns dropdown-label">Duration</span>
      <el-select
        class="select-primary pagination-select paginated-table-select"
        @change="changedDuration"
        size="mini"
        v-model="durationSelect"
      >
        <el-option
          class="select-primary"
          v-for="(duration, index) in duration.durations"
          :key="duration"
          :label="duration"
          :value="index"
        >
        </el-option>
      </el-select>
    </div>

    <div class="dropdown-container d-flex dropdown-min-width max_width_150">
      <span class="alert-page-dropdowns dropdown-label">Alert Type</span>
      <el-select
        class="select-primary pagination-select paginated-table-select"
        size="mini"
        @change="changedAlertType"
        v-model="alertSelect"
      >
        <el-option
          class="select-primary"
          v-for="alert in alerts"
          :key="alert"
          :label="alert"
          :value="alert"
        >
        </el-option>
      </el-select>
    </div>

    <div class="dropdown-container d-flex ml-2 dropdown-min-width max_width_150">
      <span class="alert-page-dropdowns dropdown-label">Stages</span>
      <el-select
        class="select-primary pagination-select paginated-table-select"
        size="mini"
        @change="changedStage"
        v-model="stageSelect"
      >
        <el-option
          class="select-primary"
          v-for="(stage , index) in stages"
          :key="index"
          :label="stage"
          :value="index"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["duration", "alerts", 'stages', 'selectedStage'],
    data() {
      return {
        durationSelect: this.duration.durations[this.duration.selectedDuration],
        alertSelect: this.alerts[0],
        stageSelect: this.selectedStage,
      };
    },
    watch: {
      selectedStage: function (new_value, old_value) {
        this.stageSelect = new_value
      }
    },
    methods: {
      changedDuration(durationIndex) {
        this.$emit("duration-change", durationIndex);
      },
      changedAlertType(alert) {
        this.$emit("alert-change", alert);
      },
      changedStage(stage) {
        this.$emit("stage-change", stage);
      },

    },
  };
</script>

<style scoped>

  .max_width_150 {
    max-width: 150px;
  }

  .alert-page-dropdowns {
    margin-bottom: 6px;
  }

  .el-input__inner {
    height: 30px !important;
  }

</style>

<style>

.dropdown-min-width{
  min-width: 180px;
}

  .dropdown-container {
    flex-direction: column;
  }

  .dropdown-label {
    font-size: 12px;
  }
</style>
