<template>
  <div class="content">
    <div class="mt-3">
      <div class="col-12 d-flex  flex-wrap">
        <template>
          <div class="input-sm mt--1 col-sm-3 col-12 px-0 dropdown-min-width" style="width: 340px">
            <base-input
              label="Search"
              inputClasses="form-control-sm"
              prepend-icon="fas fa-search"
              placeholder="Search..."
              @change="search"
              v-model="searchQuery"
              type="search"
              @search="search"

            >
            </base-input>
          </div>
          <slot></slot>

        </template>
      </div>
      <el-table
        ref="multipleTable"
        :data="filtered_duplicates_data"
        row-key="id"
        @selection-change="alertsMultiSelect"
        header-row-class-name="thead-light"
      >
        <el-table-column align="middle"
                         v-for="(column,index) in tableColumns"
                         :key="column.label"
                         v-bind="column">

          <template scope="scope" v-if="column.prop == 'holding_account'">
            <el-tooltip
              class="item" effect="dark"
              :content="hoverAccountInfo(scope.row.dummy_patient)"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <span> {{ scope.row.dummy_patient.first_name + ' ' + scope.row.dummy_patient.last_name }} </span>
            </el-tooltip>
          </template>
          <template scope="scope" v-else-if="column.prop == 'holding_account_email'">
            <span> {{ scope.row.dummy_patient.email }} </span>
          </template>
          <template scope="scope" v-else-if="column.prop == 'patient_account'">
            <el-tooltip
              class="item" effect="dark"
              :content="hoverAccountInfo(scope.row.original_patient)"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <span> {{ scope.row.original_patient.first_name + ' ' + scope.row.original_patient.last_name }} </span>
            </el-tooltip>
          </template>
          <template scope="scope" v-else="column.prop == 'patient_account_email'">
            <span> {{ scope.row.original_patient.email }} </span>
          </template>

        </el-table-column>
        <el-table-column align="middle" label="Actions">
          <div slot-scope="{ $index, row }" class="d-flex">

            <button class="merge_btn" @click="handleEdit(row, $index)">
              Merge
            </button>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <modal :show.sync="showModal">

      <h5 slot="header" class="modal-title" id="modal-title-default">Merge Account</h5>
      <div class="">
        <p class="theme-paragraph">
          Note: Once merged, the holding account will be deleted.
        </p>
      </div>

      <div class="row mt-5">
        <div class="col-6">
          <p class="grey_heading">
            HOLDING ACCOUNT
          </p>
          <div class="p-2 name_div">
            <p class="black_text long_text_dots m-1">
              {{ selected_row.dummy_patient ? selected_row.dummy_patient.email : '' }} </p>
            <span class="merging_arrow_style"><img src="/img/merging_arrow.svg" alt=""></span>

          </div>
          <div class="mt-2 border_div p-3">
            <h4 class="mb-0 long_text_dots color-maroon">
              {{ selected_row.dummy_patient ? selected_row.dummy_patient.first_name + ' ' + selected_row.dummy_patient ? selected_row.dummy_patient.last_name : '' : '' }}</h4>
            <p class="mb-3 black_text long_text_dots">
              {{ selected_row.dummy_patient ? selected_row.dummy_patient.email : '' }}</p>
            <p class="mb-1 black_text"><span class="mr-2"><img height="16" src="/img/blue_tick.svg" alt=""></span>Checklist
              data</p>
            <p class="mb-1 black_text"><span class="mr-2"><img height="16" src="/img/blue_tick.svg" alt=""></span>Pipeline
              stage</p>
            <p class="mb-1 black_text"><span class="mr-2"><img height="16" src="/img/blue_tick.svg" alt=""></span>MRN
            </p>
            <p class="mb-1 black_text"><span class="mr-2"><img height="16" src="/img/blue_tick.svg" alt=""></span>User
              categories</p>
            <p class="mb-0 black_text"><span class="mr-2"><img height="16" src="/img/blue_tick.svg" alt=""></span>Surgery
              information</p>
          </div>

        </div>
        <div class="col-6">
          <p class="grey_heading">
            PATIENT ACCOUNT
          </p>

          <div class="p-2 name_div">
            <p class="black_text long_text_dots m-1">
              {{ selected_row.original_patient ? selected_row.original_patient.email : '' }}</p>
          </div>

          <div class="mt-2 border_div p-3">
            <h4 class="mb-0 long_text_dots color-maroon">
              {{ selected_row.original_patient ? selected_row.original_patient.first_name + ' ' + selected_row.original_patient ? selected_row.original_patient.last_name : '' : '' }}</h4>
            <p class="mb-3 black_text long_text_dots">
              {{ selected_row.original_patient ? selected_row.original_patient.email : '' }}</p>

          </div>
        </div>
      </div>

      <div class="mt-4" style="float: right">
        <el-button class="height_45 cancel-btn" @click="handleClose">Cancel</el-button>
        <el-button class="height_45 crimson_btn" @click="mergePatient(selected_row.dummy_patient.id)">Merge</el-button>
      </div>
    </modal>
  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from "element-ui";
import {BasePagination} from "@/components";
import Helper from "@/util/globalHelpers";


export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: [],
  data() {
    return {
      showModal: false,
      searchQuery: '',
      selectedIndex: 0,
      filtered_duplicates_data: [],   //We can use computed property instead but it will break reactivity sometimes in vue2, and in suficient to use when pass arg.
      duplicates_data: [],
      selected_row: [],
      tableColumns: [
        {
          prop: "holding_account",
          label: "Holding Account",
        }, {
          prop: "holding_account_email",
          label: "Holding Account Email",
        },
        {
          prop: "patient_account",
          label: "Patient Account",
        },
        {
          prop: "patient_account_email",
          label: "Patient Account Email",
        }
      ],
    };
  },
  created() {
    this.fetchDuplicates();
  },
  methods: {
    hoverAccountInfo(row) {
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true) ? returnable + row.first_name : returnable + ''
      returnable = (row.hasOwnProperty('last_name') === true) ? returnable + ' ' + row.last_name : returnable + ''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable + ', ' + row.email : returnable + ''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable + ', ' + row.phone : returnable + ''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null) {
        if (!isNaN(Date.parse(row.dob))) {
          const date = new Date(row.dob)
          returnable = returnable + ', ' + Helper.extractAndFormatDate(date.toDateString(), false)
        }
      } else {
        returnable = returnable + ''
      }
      returnable = (row.hasOwnProperty('mrn') === true && row.mrn !== '' && row.mrn != null) ? returnable + ', ' + row.mrn : returnable + ' '
      return returnable
    },
    mergePatient(dummy_patient_id) {
      let vm = this;
      vm.loading = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/patients/merge/' + dummy_patient_id
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.loading = false;
        vm.showModal = false;
        let duplicateItem = vm.filtered_duplicates_data[this.selectedIndex];
        let index = vm.duplicates_data.findIndex(item => item.original_patient.id === duplicateItem.original_patient.id);
        vm.$delete(vm.duplicates_data, index)
        this.$delete(vm.filtered_duplicates_data, this.selectedIndex);
        vm.$notify.success({
          title: 'Message',
          message: 'Patient Merged Successfully'
        });
      }).catch(error => {
      });
    },
    fetchDuplicates() {
      let vm = this;
      vm.loading = true;
      let config = {
          params: {
            search: null,
          }
      }
      axios.get(this.$store.getters.getBaseUrl + '/api/patients/duplicates',config
      ).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.filtered_duplicates_data = _.clone(response.data.data)
        vm.duplicates_data = _.clone(response.data.data)
        vm.loading = false;
      }).catch(error => {
      });
    },
    handleEdit(row, index) {
      this.showModal = true
      this.selected_row = row
      this.selectedIndex = index
    },
    handleClose(index, row) {
      this.showModal = false
    },
    search() {
      this.filterDuplicates();
    },
    changePage(page) {
      this.$emit("changed-page", page);
    },
    alertsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
      this.$emit('alertsMultiSelect', selectedRows);
    },
    getName(row) {
      if (row.name.trim() === '') {
        return row.patient.first_name + ' ' + row.patient.last_name;
      }
      // return row.patient_name
      return row.name
    },
    alertMessage(alert, type) {
      let newAlertMessage = alert;
      if (type == 1 || type == '1') {
        if (alert.trim() !== '') {
          if (alert.includes('is next item due in checklist') || alert.includes('checked off any checklist item in over 2 months')) {
            return newAlertMessage;
          } else {
            newAlertMessage = "<b>Checked off </b>" + newAlertMessage;
          }
        }
      }
      return newAlertMessage
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    filterDuplicates() {
      this.filtered_duplicates_data = this.duplicates_data.filter((element) => {
        let dummyPatientName = element.dummy_patient.hasOwnProperty('first_name') ? (element.dummy_patient.first_name + " ") : "";
        dummyPatientName += element.dummy_patient.hasOwnProperty('last_name') ? element.dummy_patient.last_name : "";

        let originalPatientName = element.original_patient.hasOwnProperty('first_name') ? (element.original_patient.first_name + " ") : "";
        dummyPatientName += element.original_patient.hasOwnProperty('last_name') ? element.original_patient.last_name : "";

        let dummyPatientEmail = element.dummy_patient.email;
        let originalPatientEmail = element.original_patient.email;
        if (dummyPatientEmail.includes(this.searchQuery) || originalPatientEmail.includes(this.searchQuery) || dummyPatientName.includes(this.searchQuery) || originalPatientName.includes(this.searchQuery)) {
          return true;
        }
        return false;
      });
    }

  },
  mounted() {
    this.searchQuery = this.parentSearch;
  },
};
</script>
<style scoped>
.black_text {
  text-align: left;
  font: normal normal 600 14px/17px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.grey_heading {
  color: #8898AA;
  font: normal normal 600 11px/9px Open Sans;
}

.long_text_dots {
  overflow: hidden;
  width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.merge_btn {
  border: navajowhite;
  font-size: 15px;
  height: 32px;
  width: 70px;
  background-color: #f7f5f5;
}

.border_div {
  height: 230px;
  border: 1px solid #DEE2E6;
  border-radius: 5px;
}

.name_div {
  background: #EDF0F8;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #DEE2E6;;
}

.crimson_btn {
  top: 505px;
  left: 850px;
  width: 130px;
  height: 38px;
  background: #832C44 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 0px;
  color: white;
  opacity: 1;
}

.crimson_btn:hover {
  top: 505px;
  left: 850px;
  width: 130px;
  background: #832C44 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #32325D26;
  border-radius: 6px;
  border: 0px;
  color: white;
  opacity: 1;
}

.height_45 {
  height: 45px;
}

.merging_arrow_style {
  float: right;
  margin-top: -25px;
  margin-right: -36px;
}
</style>

<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
